import React from "react";
import "./header.css";
import assets from "../../config/assets";
import { useState, useEffect } from "react";
import {
    getCurrentUserData,
    loadScriptByURL,
    setContactFormReferralCode,
    setReferralCode,
} from "../../utils/util";
import { Button, Typography, Container, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import conditional_configs from "../../config/conditional_config";
import ContactPopup from "../../components/contact-popup/contact-popup";
import LoginButton from "../../components/login-button/login-button";
import { useNavigate } from "react-router-dom";
import UserNameDetails from "../../components/user-name-details/user-name-details";

export default function Header() {
    const [contactDialogOpen, setContactDialogOpen] = useState(false);
    const contactOpenDialog = () => {
        setContactDialogOpen(true);
    };

    // mobile view toggle function
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    // mobile view toggle function end

    const navigate = useNavigate();
    return (
        <div className="">
            <Box
                className="large-screen"
                sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >

                {/* header */}
                <div className="navbar-header-hv4">
                    <nav className="navbar navbar-hv3 navbar-expand-lg navbar-light bg-light">
                        <div className="container">
                            <div className="">
                                <img
                                    src={assets.homev3.meeku_mobile_logo}
                                    className="mobile-logo"
                                ></img>
                            </div>

                            <div className="collapse navbar-collapse" id="navbarsExample05">
                                <ul className="navbar-nav ml-auto pl-lg-5 pl-0">


                                    {/* <li className="nav-item">
                                        <a
                                            className="nav-link nav-link-text-hv4"
                                            href={conditional_configs.tempCoursesUrl.freeCoursesUrl}
                                        >
                                            Enroll for a Free Demo
                                        </a>
                                    </li> */}

                                    <li className="nav-item" onClick={(e) => contactOpenDialog()}>
                                        <a className="nav-link nav-link-text-hv4 ps-4">
                                            Contact us
                                        </a>
                                    </li>

                                    {/* <li className="nav-item" onClick={() =>
                                        navigate('/subscription')}>
                                        <a className="nav-link nav-link-text-hv4 ps-4">
                                            Pricing
                                        </a>
                                    </li> */}
                                </ul>
                            </div>

                            {!getCurrentUserData() ? (
                                <ul className="navbar-nav me-auto">
                                    <li className="nav-item cta-btn">
                                        <LoginButton></LoginButton>
                                    </li>

                                    <li>
                                        <button className="start-free-btn-hv4" onClick={() =>
                                            navigate(conditional_configs.tempCoursesUrl.freeCoursesUrl)}>Start for FREE</button>
                                    </li>
                                </ul>
                            ) : (
                                <ul className="navbar-nav me-auto">
                                    <li className="nav-item cta-btn">
                                        <UserNameDetails></UserNameDetails>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </nav>
                </div>

            </Box>

            <Box
                className="mobile-screen"
                sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
                {/* header */}
                <nav className="navbar navbar-hv3-mobile navbar-expand-lg navbar-light bg-light">
                    <div className="container">
                        <div className="container d-flex align-items-center p-0">
                            <button
                                className="navbar-toggler toggler-icon-hv3"
                                type="button"
                                onClick={toggleMenu}
                            >
                                {isMenuOpen ? (
                                    <CloseIcon color="secondary" sx={{ fontSize: 40 }} /> // Close icon
                                ) : (
                                    <MenuIcon color="secondary" sx={{ fontSize: 40 }} /> // Open icon
                                )}
                            </button>

                            <div className="ms-auto">
                                <img
                                    src={assets.homev3.meeku_mobile_logo}
                                    className="mobile-logo"
                                ></img>
                            </div>

                            {!getCurrentUserData() ? (
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item cta-btn">
                                        <LoginButton className={`login-btn-text-blue`} />
                                    </li>
                                </ul>
                            ) : (
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item cta-btn">
                                        <UserNameDetails></UserNameDetails>
                                    </li>
                                </ul>
                            )}
                        </div>

                        <div
                            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
                        >
                            <ul className="navbar-nav ml-auto">
                                {/* <li className="nav-item">
                  <a
                    className="nav-link nav-link-text-mobile-hv3"
                    href={conditional_configs.tempCoursesUrl.paidCoursesUrl}
                  >
                    Courses
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link nav-link-text-mobile-hv3" href="/">
                    How it works
                  </a>
                </li> */}
                                <li className="nav-item">
                                    <a
                                        className="nav-link nav-link-text-mobile-hv3"
                                        href={conditional_configs.tempCoursesUrl.freeCoursesUrl}
                                    >
                                        Start for free
                                    </a>
                                </li>

                                <li className="nav-item" onClick={(e) => contactOpenDialog()}>
                                    <a className="nav-link nav-link-text-mobile-hv3">
                                        Contact us
                                    </a>
                                </li>

                                {/* <li className="nav-item" onClick={() =>
                                    navigate('/subscription')}>
                                    <a className="nav-link nav-link-text-mobile-hv3">
                                        Pricing
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </nav>


            </Box>

            <ContactPopup
                open={contactDialogOpen}
                onClose={() => setContactDialogOpen(false)}
            ></ContactPopup>

        </div>
    )
}