import React, { useEffect, Fragment, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import moment from "moment-timezone";
import PhoneInput, {
} from "react-phone-number-input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Card,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Button,
  OutlinedInput
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  getCurrentUserData,
  getUnique,
  getCharacterValidationError,
  setReferralCode,
} from "../../../utils/util";
import "./self-learning-parent-register.css";
//COMPONENTS
import assets from "../../../config/assets";
import ParentService from "../../../services/parent-service";
import AuthService from "../../../services/auth/auth.service";
import MemberService from "../../../services/member-service";
import conditional_configs from "../../../config/conditional_config";
import { IconButton, InputAdornment } from '@mui/material';
import Iconify from "../../../components/Iconify";

// REGISTER FORM VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  parentName: Yup.string().required("Parent name is required").matches(/^[a-zA-Z0-9_]{4,15}$/, "Parent name must be 4-15 characters and alphanumeric"), // Corrected validation,,
  parentLastName: Yup.string().required("Last name is required"),
  parentEmail: Yup.string()
    .required("Email is required")
    .email("Email is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/\W/, getCharacterValidationError("symbol")),
  country: Yup.string().required("Country is required"),
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .min(9, "Invalid mobile number")
    .max(15, "Invalid mobile number"),
  // childAge: Yup.string().required("Select child age"),
  conditionsagree: Yup.bool().oneOf([true], "Accept Terms is required"),
});

const SelfLearningParentRegister = () => {

  // showtab function
  const [showtab, setShowtab] = useState(1);
  const [defaultCountry, setDefaultCountry] = useState("USA");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [showKidPassword, setShowKidPassword] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent) {
      navigate('/parent/self-learning');
    } else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.learner) {
      navigate('/learner/missions');
    } else {
      setReferralCode();
    }
  }, [])

  // useEffect(() => {
  //   setReferralCode();
  // }, []);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    if (data) {

      const ref_code = localStorage.getItem("ref_code");
      if (data.mobileNumber) {
        const parts = data.mobileNumber.split(" ");
        const cleanedNumber = parts.slice(1).join(" ");
        if (cleanedNumber.replace(" ", "").length < 9) {
          setIsSubmitting(false);
          enqueueSnackbar("Invalid mobile number", {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          return;
        }
        if (cleanedNumber.replace(" ", "").length > 15) {
          setIsSubmitting(false);
          enqueueSnackbar("Invalid mobile number", {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          return;
        }
      }
      var userData = {
        firstname: data.parentName,
        lastname: data.parentLastName,
        email: data.parentEmail,
        password: data.password,
        mobile: data.mobileNumber,
        childAge: "No_Age",
        country: data.country,
        createdby: "0",
        conditionsagree: data.conditionsagree.toString(),
        referral_code: ref_code ? ref_code : "self-learning",
      };
      console.log(userData);

      ParentService.register(userData)
        .then((response) => {
          setIsSubmitting(false);
          console.log(response);
          if (
            response &&
            response.data &&
            response.data.success === "true"
          ) {
            // variant could be success, error, warning, info, or default
            enqueueSnackbar("Parent registration is successful", {
              variant: "success",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            parentLogin(userData.email, userData.password);
          } else if (
            response &&
            response.data &&
            response.data.success === "false"
          ) {
            enqueueSnackbar(response.data.data, {
              variant: "warning",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          } else {
            enqueueSnackbar(
              "Something went wrong. Please contact Admin.",
              {
                variant: "warning",
                key: "topright",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              }
            );
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            enqueueSnackbar(
              "Something went wrong. Please contact Admin.",
              {
                variant: "error",
                key: "topright",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              }
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            enqueueSnackbar(error.message, {
              variant: "error",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          }
        });
    }
  };

  const parentLogin = (email, password) => {
    AuthService.parent_login(email, password)
      .then((response) => {
        if (response && response.success === "true") {
          setShowtab(2);
        } else if (response && response.success === "false") {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          enqueueSnackbar(error.message, {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      });
  };

  //#region ADD KID

  const kidsSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    username: Yup.string().required("User name is required").matches(/^[a-zA-Z0-9_]{4,15}$/, "Username must be 4-15 characters and alphanumeric"), // Corrected validation,

    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/\W/, getCharacterValidationError("symbol")),
  });

  const {
    register: kidsFormReg,
    control: kidsFormCtrl,
    handleSubmit: kidsFormSubmit,
    formState: { errors: kidsFormErrors },
  } = useForm({
    resolver: yupResolver(kidsSchema),
  });

  const onAddSubmit = (data) => {
    var addKidData = {
      firstname: data.firstname,
      lastname: "",
      username: data.username,
      email: getCurrentUserData().email,
      password: data.password,
      parentid: getCurrentUserData().id.toString(),
      relationship: "2",
      createdby: getCurrentUserData().id
    };
    console.log("submit add kid::", addKidData);
    console.log("submit add kid:data:", data);

    MemberService.createLearner(addKidData)
      .then((response) => {
        setIsSubmitting(false);
        console.log(response);
        if (response && response.data && response.success === "true") {
          naviateTo("/subscription")
        } else if (
          response &&
          response.data &&
          response.success === "false"
        ) {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar(
            "Something went wrong. Please contact Admin.",
            {
              variant: "warning",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          enqueueSnackbar(
            "Something went wrong. Please contact Admin.",
            {
              variant: "error",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          enqueueSnackbar(error.message, {
            variant: "error",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      });
  };



  const naviateTo = (route) => {
    navigate(route);
  };

  const [selectedCountry, setSelectedCountry] = useState("USA");

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };



  // Custom styles to fix the border issue
  const useStyles = makeStyles(() => ({
    select: {
      "& fieldset": {
        borderColor: "#ccc", // Add a default border color
      },
      "&:hover fieldset": {
        borderColor: "#999", // Add a hover color if needed
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3f51b5", // Change the border color when focused
      },
    },
  }));
  const classes = useStyles(); // Hook for custom styles

  //otp

  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [otpValid, setOtpValid] = useState(false); // To track if OTP is valid

  // Function to handle OTP change
  const handleOtpChange = (e) => {
    const value = e.target.value;
    setOtpValid(false)
    // Allow only numeric and set the OTP
    if (/^\d*$/.test(value) && value.length <= 6) {
      setOtp(value);
      setOtpError('');
    } else {
      setOtpError('Please enter a valid 6-digit OTP.');
    }
  };

  // Function to handle OTP submit
  const handleOtpSubmit = (e) => {
    e.preventDefault();
    setOtpValid(true)
    if (otp.length == 6) {

      var userOptData = {
        memberid: getCurrentUserData().id.toString(),
        otp: otp
      };
      console.log("userOptData", userOptData)
      ParentService.verifyOtp(userOptData)
        .then((response) => {
          if (response.success) {
            // If OTP is valid, show tab 3
            setOtpValid(false);
            setShowtab(3);
            enqueueSnackbar("OTP verified and account activated successfully", {
              variant: "success",
              key: "topright",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            // If OTP is valid, update the local storage
            const updatedUserData = {
              ...getCurrentUserData(), // Fetch current user data
              status_id: 1, // Assuming states_id 1
            };
            // Update local storage with the new user data
            localStorage.setItem("user", JSON.stringify(updatedUserData));
          } else {
            setOtpError('Invalid OTP, please try again.');
          }
        })
        .catch(() => {
          setOtpError('Error validating OTP, please try again.');
        });
    } else {
      setOtpError('OTP must be 6 digits.');
    }
  };

  return (
    <div className="h-100">
      <div className="row h-100 ms-0 me-0">
        <div className="col-lg-6 left-column-pr-v2 p-0" style={{ zIndex: "1" }}>
          <div className="left-column-pr-v2-section">
            <img alt=""
              src={assets.course_v2.form_meeku_logo}
              onClick={(e) => naviateTo("/")}
              className="meeku-icon-prv2 cursor-pointer"
            ></img>

            <div className="mt-4">
              <img alt=""
                src={assets.course_v2.section_1_group_img}
                className=""
              ></img>
            </div>

            {/* <Card className="Coding-With-card-prv2 mt-3">
              <Box>
                <div>
                  <div className="d-flex align-items-center">
                    <p className="Coding-With-card-le-text-prv2 mb-0 me-1">
                      Problem Solving Using Robotics
                    </p>
                  </div>

                </div>
              </Box>
            </Card> */}
          </div>
          <img src={assets.homev3.m_icon} alt="" className="m-icon-prv2"></img>
          <div className="d-flex justify-content-end">
            <img alt=""
              src={assets.course_v2.yellow_star_img}
              className="yellow_star_img_prv2 me-3"
            ></img>
          </div>

          <div className="d-flex justify-content-start">
            <img alt=""
              src={assets.login.login_cat_gif}
              className="login_cat_img me-3"
            ></img>
          </div>
        </div>
        <div className="col-lg-6 right-column-pr-v2 p-0">
          <div
            className="tab-content right-column-pr-v2-section mt-4"
            id="pills-tabContent"
          >
            <div
              className={
                showtab === 1
                  ? "tab-pane fade show active p-0"
                  : "tab-pane fade show p-0"
              }
            >
              <div>
                <p className="form-hi-text-pr-v2 m-0">Parent Registration</p>
              </div>
              <Card className="parent-reg-card-prv2 mt-2">
                <Box>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* PARENT NAME*/}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        id="parentName"
                        name="parentName"
                        label="Parent Name"
                        fullWidth
                        margin="dense"
                        error={Boolean(errors.parentName)}
                        helperText={
                          errors.parentName ? errors.parentName.message : "Parent name must be 4-15 characters and alphanumeric"
                        }
                        {...register("parentName", { required: true })}
                      />
                    </Grid>
                    {/* PARENT LASTNAME */}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        id="id-parentLastName"
                        name="parentLastName"
                        label="Last Name"
                        fullWidth
                        margin="dense"
                        error={Boolean(errors.parentLastName)}
                        helperText={
                          errors.parentLastName
                            ? errors.parentLastName.message
                            : ""
                        }
                        {...register("parentLastName", { required: true })}
                      />
                    </Grid>
                    {/* EMAIL FIELD */}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        id="parentEmail"
                        name="parentEmail"
                        label="Email"
                        fullWidth
                        margin="dense"
                        error={Boolean(errors.parentEmail)}
                        helperText={
                          errors.parentEmail ? errors.parentEmail.message : ""
                        }
                        {...register("parentEmail", { required: true })}
                      />
                    </Grid>
                    {/* PASSWORD */}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="new-password"
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        fullWidth
                        margin="dense"
                        onCopy={(e) => e.preventDefault()} // Disable copy
                        onPaste={(e) => e.preventDefault()} // Disable paste
                        error={Boolean(errors.password)}
                        helperText={
                          errors.password ? errors.password.message : ""
                        }
                        {...register("password", { required: true })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {/* COUNTRY */}

                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth required margin="dense">
                        <InputLabel id="country-label">Country</InputLabel>
                        <Controller
                          name="country"
                          control={control} // Pass react-hook-form's control
                          defaultValue={selectedCountry}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="country-label"
                              value={selectedCountry} // Controlled by local state
                              onChange={(e) => {
                                handleCountryChange(e); // Update local state
                                field.onChange(e); // Update form field value in react-hook-form
                              }}
                              input={<OutlinedInput label="Country" className={classes.select} />} // Apply custom styles here
                            >
                              <MenuItem value="India">India</MenuItem>
                              <MenuItem value="USA">United States of America</MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>

                    {/* MOBILE NUMBER */}
                    <div className="form-group">
                      <div className="input-group">
                        <FormControl fullWidth required margin="dense">
                          <PhoneInput
                            id="mobile-number"
                            name="mobileNumber-name"
                            label="Mobile number"
                            className="w-100 border border-gray-300 bg-white px-4 py-3 text-sm shadow-none parent-reg-mobile-number"
                            maxLength="15"
                            defaultCountry={selectedCountry === "India" ? "IN" : "US"} // Change default country based on selectedCountry
                            international
                            countryCallingCodeEditable={true}
                            countries={['US', 'IN']} // Only show US and Canada flags
                            placeholder="Enter phone number with country code"
                            error={Boolean(errors.mobileNumber)}
                            {...register("mobileNumber", { required: true })}
                          />
                          {errors.mobileNumber ? (
                            <FormHelperText className="requiredField">
                              {errors.mobileNumber.message}
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </FormControl>
                      </div>
                    </div>

                    {/* CHILD AGE */}
                    {/* <Grid item xs={12} sm={12}>
                      <FormControl fullWidth required margin="dense">
                        <InputLabel id="child-age-label">Select Age</InputLabel>
                        <Select
                          labelId="child-age-label"
                          id="childAge"
                          name="childAge"
                          label="Child Age"
                          error={Boolean(errors.childAge)}
                          {...register("childAge", { required: true })}
                          helperText={
                            errors.childAge ? errors.childAge.message : ""
                          }
                        >
                          <MenuItem value="5-9">5-9 Years</MenuItem>
                          <MenuItem value="10-14">10-14 Years</MenuItem>
                        </Select>
                        {errors.childAge ? (
                          <FormHelperText className="requiredField">
                            {errors.childAge.message}
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid> */}
                    {/* REFERRAL CODE */}
                    {/* <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        id="id-referralCode"
                        name="referralCode"
                        label="Referral code"
                        fullWidth
                        margin="dense"
                        error={Boolean(errors.referralCode)}
                        helperText={
                          errors.referralCode ? errors.referralCode.message : ""
                        }
                        {...register("referralCode", { required: false })}
                      />
                    </Grid> */}
                    {/* ACCEPT TERMS AND CONDITIONS */}
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Controller
                            control={control}
                            name="conditionsagree"
                            defaultValue="false"
                            inputRef={register()}
                            render={({ field: { onChange } }) => (
                              <Checkbox
                                color="primary"
                                onChange={(e) => onChange(e.target.checked)}
                              />
                            )}
                          />
                        }
                        label={
                          <Typography
                            color={errors.conditionsagree ? "error" : "inherit"}
                          >
                            By proceeding, you agree to our{" "}
                            <a href="/terms-and-conditions">
                              Terms and Conditions
                            </a>{" "}
                            *
                          </Typography>
                        }
                      />
                      <br />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.conditionsagree
                          ? "(" + errors.conditionsagree.message + ")"
                          : ""}
                      </Typography>
                    </Grid>
                    <div className="d-flex mt-4 pb-3">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="Submit-btn-prv2"
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </form>
                  {/* <a href="#" onClick={() => handletabclick(2)}>
                    go to next
                  </a> */}
                </Box>
              </Card>
            </div>
            {/* opt verification tab */}
            <div
              className={showtab === 2 ? 'tab-pane fade show active p-0' : 'tab-pane fade show p-0'}
            >
              <p className="form-hi-text-pr-v2 m-0">OTP Validation</p>
              <Card className="parent-reg-card-prv2 mt-2">
                <div className="row">
                  {/* FORM SECTION */}
                  <div className="col-12">
                    <Grid item xs={12}>
                      <p className="small-note-text">
                        Please check your registered email for your OTP. Please enter the OTP to activate your account.
                      </p>
                    </Grid>
                    <Grid container spacing={1}>
                      {/* OTP INPUT FIELD */}
                      <Grid item xs={12} sm={12} className="mt-2">
                        <TextField
                          required
                          id="otp"
                          name="otp"
                          label="Enter 6-digit OTP"
                          fullWidth
                          margin="dense"
                          value={otp}
                          onChange={handleOtpChange}
                          error={Boolean(otpError)}
                          helperText={otpError}
                        />
                      </Grid>

                      {/* SUBMIT BUTTON */}
                      <Grid item xs={12} sm={12}>
                        <button
                          disabled={otpValid}
                          type="submit"
                          onClick={handleOtpSubmit}
                          className="Submit-btn-prv2 mb-3 mt-1"
                        >
                          <span>Submit OTP</span>
                        </button>
                      </Grid>
                    </Grid>
                  </div>
                  {/* FORM SECTION END*/}
                </div>
              </Card>
            </div>
            {/* Add Kid Tab*/}
            <div
              className={
                showtab === 3
                  ? "tab-pane fade show active p-0"
                  : "tab-pane fade show p-0"
              }
            >
              <p className="form-hi-text-pr-v2 m-0">Add Kid</p>
              <Card className="parent-reg-card-prv2 mt-2">
                <div className="row">
                  {/* FORM SECTION */}
                  <div className="col-12">

                    <Grid container spacing={1}>
                      {/* FIRST NAME TEXT FIELD */}
                      <Grid item xs={12} sm={12} className="mt-2">
                        <TextField
                          required
                          id="firstname"
                          name="firstname"
                          label="Name"
                          // defaultValue={kids_datas.firstname}
                          fullWidth
                          margin="dense"
                          {...kidsFormReg("firstname")}
                          error={Boolean(kidsFormErrors.firstname)}
                          helperText={
                            kidsFormErrors.firstname
                              ? kidsFormErrors.firstname?.message
                              : ""
                          }
                        />

                      </Grid>
                      {/* LAST NAME TEXT FIELD */}
                      {/* <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          id="lastname"
                          name="lastname"
                          label="Last Name"
                          //defaultValue={kids_datas.lastname}
                          fullWidth
                          margin="dense"
                          {...kidsFormReg("lastname")}
                          error={kidsFormErrors.lastname ? true : false}
                          helperText={
                            kidsFormErrors.lastname
                              ? kidsFormErrors.lastname?.message
                              : ""
                          }
                        />

                      </Grid> */}

                      {/* USERNAME */}
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          id="username"
                          name="username"
                          label="User Name"
                          //defaultValue={kids_datas.username}
                          fullWidth
                          margin="dense"
                          {...kidsFormReg("username")}
                          autoComplete="new-username"
                          error={kidsFormErrors.username ? true : false}
                          helperText={
                            kidsFormErrors.username
                              ? kidsFormErrors.username?.message
                              : <span className="dont-type-real-name">Don’t type your real name & Username must be 4-15 characters and alphanumeric</span>
                          }
                        />

                      </Grid>

                      {/* PASSWORD FIELD */}
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          autoComplete="new-password"
                          id="password"
                          name="password"
                          label="Password(for kids login)"
                          type={showKidPassword ? 'text' : 'password'}
                          //defaultValue={kids_datas.password}
                          fullWidth
                          margin="dense"
                          onCopy={(e) => e.preventDefault()} // Disable copy
                          onPaste={(e) => e.preventDefault()} // Disable paste
                          {...kidsFormReg("password")}
                          error={kidsFormErrors.password ? true : false}

                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowKidPassword(!showKidPassword)} edge="end">
                                  <Iconify icon={showKidPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          helperText={
                            kidsFormErrors.password
                              ? kidsFormErrors.password?.message
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <button
                          type="submit"
                          onClick={kidsFormSubmit(onAddSubmit)}
                          className="Submit-btn-prv2 mb-3 mt-1"
                        >
                          <span>Submit</span>
                        </button>
                      </Grid>


                    </Grid>

                  </div>

                  {/* FORM SECTION END*/}
                </div>
              </Card>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <img alt=""
              src={assets.homev3.section3_star_img}
              className="section3_star_img_prv2 me-3"
            ></img>
          </div>

          <div className="d-flex justify-content-start mt-2">
            <img alt=""
              src={assets.homev3.interactive_section_mm_img}
              className="interactive_section_mm_img_prv2"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfLearningParentRegister;
