import React, { useState } from 'react';
import { useEffect } from 'react';
import Joyride from 'react-joyride';
import tour_Manager_Steps from '../../config/tour_start';
import Iconify from '../Iconify';
import icons_config from '../../config/icons';
import './tour-manager.css'
import { getCurrentUserData } from '../../utils/util';
import conditional_configs from '../../config/conditional_config';
import navigate_route_config from '../../config/navigate_route_config';

const TourManager = () => {

  const [currentUrl, setCurrentUrl] = useState();


  const getIcon = (name) => <Iconify icon={name} width={20} height={20} sx={{ marginRight: '4px', marginLeft: '4px' }} />;
  const [runTour, setRunTour] = useState(false);


  useEffect(() => {
    const CurrentUrl = window.location.pathname;
    setCurrentUrl(CurrentUrl)
    console.log(currentUrl)
  });

  const disableBodyScroll = () => {
    document.body.classList.add('no-scroll'); // Add no-scroll class to <body>
  };

  const enableBodyScroll = () => {
    document.body.classList.remove('no-scroll'); // Remove no-scroll class from <body>
  };

  const startTour = () => {
    disableBodyScroll();
    setRunTour(true);
  };

  const stopTour = () => {
    enableBodyScroll();
    setRunTour(false);
  };

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      enableBodyScroll();
    };
  }, []);

  const customStyles = {
    options: {
      zIndex: 1000,
    },
    tooltip: {
      backgroundColor: '#F7F7F7', // Light background color
      borderRadius: '12px', // Rounded corners
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Soft shadow
      padding: '16px', // Padding inside the tooltip
      textAlign: 'center', // Centered text
    },
    tooltipTitle: {
      color: '#333', // Title color
      fontSize: '18px', // Title font size
      fontWeight: 'bold', // Bold title
      marginBottom: '8px', // Space below title
    },
    tooltipContent: {
      color: '#000000', // Content text color
      fontSize: '14px', // Content font size
      lineHeight: '1.5', // Line spacing
      fontFamily: 'Poppins Regular',
      // animation: 'fadeIn 2s ease-in-out', // Add animation
    },
    buttonNext: {
      backgroundColor: '#4011C6', // Green button
      color: '#fff', // White text
      borderRadius: '22px', // Rounded button
      padding: '8px 16px', // Padding inside the button
      fontSize: '14px', // Button text size
      border: 'none', // Remove border
      cursor: 'pointer', // Pointer cursor on hover
      fontFamily: 'Poppins Regular'
    },
    buttonBack: {
      color: '#4011C6', // Green text for back button
      backgroundColor: 'white', // Green button
      fontSize: '14px', // Text size
      marginRight: '10px', // Space to the right of the back button
      fontFamily: 'Poppins Regular',
      border: "1px solid #4011C6",
      borderRadius: '22px', // Rounded button
      padding: '6px 16px', // Padding inside the button
      fontSize: '14px', // Button text size
    },
    buttonSkip: {
      color: '#838383', // Red text for skip button
      fontSize: '14px', // Text size
      fontFamily: 'Poppins Regular'
    },

    buttonClose: {
      display: 'none',
    },

  };
  // Define the CSS animation in your CSS file or as global styles
  const animationStyles = `
  @keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
`;

  // Inject animation styles dynamically
  const styleSheet = document.createElement('style');
  styleSheet.type = 'text/css';
  styleSheet.innerText = animationStyles;
  document.head.appendChild(styleSheet);


  return (
    <div>

      {/* <button onClick={stopTour}>Stop Tour</button> */}
      {
        getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.learner && currentUrl == navigate_route_config.learner.my_courses ?
          <div>
            <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.tourStartIcon)}Start Tour</button>
            <Joyride
              steps={tour_Manager_Steps.learnerTourSteps}
              run={runTour}
              continuous={true}
              showProgress={true}
              showSkipButton={true}
              locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
            />
          </div>
          :
          getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert && currentUrl == navigate_route_config.expert.my_courses ?
            <div>
              <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.tourStartIcon)}Start Tour</button>
              <Joyride
                steps={tour_Manager_Steps.expertTourSteps}
                run={runTour}
                continuous={true}
                showProgress={true}
                showSkipButton={true}
                locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
              />
            </div>

            :
            getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.learner && currentUrl == navigate_route_config.learner.missions ?
              <div>
                <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.iconTour)}Start Tour</button>
                <Joyride
                  steps={tour_Manager_Steps.learnerShareCodeGameTourSteps}
                  run={runTour}
                  continuous={true}
                  showProgress={true}
                  showSkipButton={true}
                  locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
                  styles={customStyles}
                  callback={(data) => {
                    const { status } = data;
                    if (status === 'started') {
                      startTour();
                    } else if (status === 'finished' || status === 'skipped') {
                      stopTour();
                    }
                  }}
                />
              </div>
              :
              getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.learner && currentUrl == navigate_route_config.learner.challenges ?
                <div>
                  <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.iconTour)}Start Tour</button>
                  <Joyride
                    steps={tour_Manager_Steps.learnerShareCodeWhiteboarTourSteps}
                    run={runTour}
                    continuous={true}
                    showProgress={true}
                    showSkipButton={true}
                    locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
                    styles={customStyles}
                    callback={(data) => {
                      const { status } = data;
                      if (status === 'started') {
                        startTour();
                      } else if (status === 'finished' || status === 'skipped') {
                        stopTour();
                      }
                    }}
                  />
                </div> :
                //parent
                getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent && currentUrl == navigate_route_config.parent.my_courses ?
                  <div>
                    <button className='Take-the-Tour-btn-myc' onClick={startTour}>Take the Tour</button>
                    <Joyride
                      steps={tour_Manager_Steps.parentCoursesTourSteps}
                      run={runTour}
                      continuous={true}
                      showProgress={true}
                      showSkipButton={true}
                      locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
                      styles={
                        {
                          buttonClose: {
                            display: 'none',
                          },
                        }
                      }
                    />
                  </div> :
                  getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent && currentUrl == navigate_route_config.parent.my_kids ?
                    <div>
                      <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.tourStartIcon)}Start Tour</button>
                      <Joyride
                        steps={tour_Manager_Steps.parentKidsTourSteps}
                        run={runTour}
                        continuous={true}
                        showProgress={true}
                        showSkipButton={true}
                        locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
                        styles={
                          {
                            buttonClose: {
                              display: 'none',
                            },
                          }
                        }
                      />
                    </div> :
                    getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent && currentUrl == navigate_route_config.parent.home ?
                      <div>
                        <button className='tour-start-btn' onClick={startTour}>{getIcon(icons_config.tourStartIcon)}Start Tour</button>
                        <Joyride
                          steps={tour_Manager_Steps.parentOtherCoursesTourSteps}
                          run={runTour}
                          continuous={true}
                          showProgress={true}
                          showSkipButton={true}
                          locale={{ next: 'Next', back: 'Back', skip: 'Skip' }}
                          styles={
                            {
                              buttonClose: {
                                display: 'none',
                              },
                            }
                          }
                        />
                      </div> : ""
      }
    </div>
  );
};

export default TourManager;
