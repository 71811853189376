import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useEffect } from 'react';

// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import Logo from '../../components/Logo';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import TourManager from '../../components/tour-manager/tour-manager';

import { getCurrentUserData } from '../../utils/util';
import conditional_configs from '../../config/conditional_config';
import navigate_route_config from '../../config/navigate_route_config';

// import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 55;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  borderBottom: '1px solid #D4D9DF',
  backgroundColor: '#fff',
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    width: `100%`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 3),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {

  const [currentUrl, setCurrentUrl] = useState();

  useEffect(() => {
    const CurrentUrl = window.location.pathname;
    setCurrentUrl(CurrentUrl)
  });

  return (
    <RootStyle style={{ zIndex: "2" }}>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box sx={{ display: 'inline-flex', justifyContent: "center" }}>
          <Logo />
        </Box>
        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* {getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent && currentUrl == navigate_route_config.parent.my_courses ?
            "" : <TourManager></TourManager>} */}
          {/* <LanguagePopover /> */}
          {getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent ? <NotificationsPopover /> : ""}
          <div className="profile-tour-1">
            <AccountPopover />
          </div>
        </Stack>

      </ToolbarStyle>
    </RootStyle>
  );
}
