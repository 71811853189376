import React, { useState, useEffect, useRef } from "react";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import slugify from 'react-slugify';
import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../../components/hook-form';
import "./course-detail.css"
import SelfLearningService from "../../../../services/self-learning-service";
import { findTypeOfMessage, getChats, getCurrentUserData, getInitialsFromFullName, storeChats } from "../../../../utils/util";
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, Stack, TextField } from "@mui/material";
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from "@mui/icons-material/Close";
import games from "../../../../config/games";
import assets from "../../../../config/assets";
import conditional_configs from "../../../../config/conditional_config";
import UtilService from "../../../../services/util.service";
import game_config from "../../../../config/game_config";
import io from "socket.io-client";
import url_configs from "../../../../config/url";
import GameService from "../../../../services/games-service";
import { fDate } from "../../../../utils/formatTime";
import FirebaseService from "../../../../services/firebase-service";
import Iconify from "../../../../components/Iconify";
import icons_config from "../../../../config/icons";
import SessionService from "../../../../services/session-service";
import storage_configs from "../../../../config/storage-config";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import SendIcon from '@mui/icons-material/Send';
import PaymentService from "../../../../services/payment-service";
import navigate_route_config from "../../../../config/navigate_route_config";
import { CircularProgress } from '@mui/material'; // For the loader spinner

let b_room_socket;
let chat_server_socket;
let friends_server_socket;

//#region MUI TABS
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
//#endregion

//#region MUI Card
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
//#endregion
export default function CourseDetail() {
  const getIcon = (name) => <Iconify icon={name} width={30} height={30} />;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [myGames, setMyGames] = React.useState([]);
  const [myBoards, setMyBoards] = React.useState([]);
  const [players, setPlayers] = React.useState([]);
  const [gameResults, setGameResults] = React.useState([]);
  const [openGameShareCodePopup, setGameShareCodePopup] = useState(false);
  const [openedContent, setOpenedContent] = useState();
  const [openedGroupData, setOpenedGroupData] = useState();
  const [openJoinWithCodePopup, setOpenJoinWithCodePopup] = useState(false);
  const [openGameSettings, setOpenGameSettings] = useState(false);
  const [gameSettingLink, setGameSettingLink] = useState();

  const [openJoinWithCodeGame, setOpenJoinWithCodeGame] = useState();

  //WHITEBOARD TAB DECLARATIONS
  const [openBoardShareCodePopup, setOpenBoardShareCodePopup] = useState(false);
  const [openWhiteboardTabScreen, setOpenWhiteboardTabScreen] = useState(false);
  const [whiteBoardTabList, setWhiteBoardTabList] = React.useState([]);
  const [whiteboardLink, setWhiteboardLink] = React.useState();
  const [whiteboardKey, setWhiteboardKey] = React.useState();

  //CHATS
  const [enableChatWindow, setEnableChatWindow] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  //MY FRIENDS LIST
  const [myIntractedFriendsList, setMyIntractedFriendsList] = React.useState([]);
  const [myChatFriendsList, setMyChatFriendsList] = React.useState([]);
  const [myFriendsAnchorEl, setMyFriendsAnchorEl] = useState();
  const [myFriendsCtrlMenu, setMyFriendsCtrlMenu] = useState(false);
  const [openIndividualChat, setOpenIndividualChat] = useState();
  const [chatMessages, setChatMessages] = useState([]);
  const [inviteFriendPopup, setInviteFriendPopup] = useState(false);
  const [userName, setUserName] = useState(false);

  const [myChatInviteList, setMyChatInviteList] = useState([]);

  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [isLoading, setIsLoading] = useState(true); // Loader state

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  //Get course details
  useEffect(() => {
    getMySubscriptionDetail();
    getCourseDetailByParentId();
    getMyInvites();
  }, []);

  const getMyInvites = () => {
    SelfLearningService.getMyInvitesByParent().then(response => {
      if (response && response.success == "true") {
        if (response.data.length > 0) {
          localStorage.setItem("my-invites", JSON.stringify(response.data));
        }
      }
    })
      .catch(e => {
        console.log(e);
      });
  }

  /** GET COURSE DETAIL */
  const getCourseDetailByParentId = () => {
    setIsLoading(true); // Show loader while fetching data

    SelfLearningService.getCourseDetailByParentId(getCurrentUserData().id).then(response => {
      console.log('getCourseDetailByParentId::', response);
      if (response && response.success == "true") {
        setMyGames(response.data.games);
        setMyBoards(response.data.boards)
      }
    })
      .catch(e => {
        console.log(e);
      }).finally(() => {
        setIsLoading(false); // Hide loader after data is fetched
      });
  }

  const getGameDetail = (id) => {
    if (id)
      return games.gameList.filter(e => e.id == id)[0];
  }

  /** HANDLE OPEN **WHITEBOARD** TAB SHARE CODE POPUP */
  const handleOpenBoardShareCodePopup = (e, obj) => {
    if (obj.isFree) {
      setEnableChatWindow(false);
      setOpenBoardShareCodePopup(true);
      setOpenedContent(obj);
      setPlayers([]);
      localStorage.setItem('openedContent', JSON.stringify(obj));
      SelfLearningService.getSelfLearningGroupDetailByCourseIdAndLearnerId(obj.course_id, getCurrentUserData().id, obj.id, conditional_configs.curriculam_types.whiteboard).then(response => {
        console.log('getSelfLearningGroupDetailByCourseIdAndLearnerId::', response);
        if (response && response.data && response.success == "true") {
          setPlayers(response.data.players);
          setOpenedGroupData(response.data.groupData);
          localStorage.setItem('openedGroupData', JSON.stringify(response.data.groupData));
          SelfLearningService.getBoardsTemplatesById(response.data.groupData.self_learning_board_id)
            .then((response) => {
              console.log("getBoardsTemplatesById", response);
              if (response.data) {
                localStorage.setItem('actualWhiteboardTemplates', JSON.stringify(response.data.whiteboardTemplates));
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
        .catch(e => {
          console.log(e);
        });
    }
  }

  /** HANDLE CLOSE **WHITEBOARD** TAB SHARE CODE POPUP */
  const handleBoardShareCodePopupClose = () => {
    setOpenBoardShareCodePopup(false);
    setOpenWhiteboardTabScreen(false);
    localStorage.removeItem("openedGroupData");
    setOpenedGroupData(null);
    setEnableChatWindow(false);
  };

  /** GET **GAME** RESULTS BY SHARED CODE */
  const getGameResultBySlugAndUserId = (code) => {
    GameService.getGameResultBySlugAndUserId(code)
      .then((response) => {
        console.log("Gameresults", response);
        setGameResults([]);
        if (response && response.data && response.data.length > 0) {
          setGameResults(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openWhiteBoard = (e, obj, index) => {
    e.stopPropagation();
    setWhiteboardLink("");
    let data = [...whiteBoardTabList];
    data.forEach((element) => {
      element.active = false;
    });
    setWhiteboardKey(obj.id);
    let generateBoardLink = url_configs.white_board_server + getCurrentUserData().name + "&self_learning_board_id=" + openedContent.id + "#room=" + obj.board_slug.toLowerCase() + ",-4-Gf1BCSf5ns_M4ChSamw";
    setTimeout(() => {
      setWhiteboardLink(generateBoardLink);
    }, 500);
    data[index].active = true;
    setWhiteBoardTabList(data);
  };

  const navigateTo = (link) => {
    navigate(link);
  }

  const getMySubscriptionDetail = () => {
    PaymentService.getMySubscriptionDetail()
      .then((response) => {
        console.log("getMySubscriptionDetail", response);
        if (response && response.data && response.data.length > 0) {
          setSubscriptionStatus(response.data[0].subscription.status);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const openDetail = (whiteboardName, data) => {
    // Save the clicked object data to localStorage
    localStorage.setItem(storage_configs.localStorage.sessions.selectedCardDescriptionDetails, JSON.stringify(data))
    navigateTo(navigate_route_config.parent.courses_description + "/" + whiteboardName);
  }

  const getBoardStyles = (boardName) => {
    switch (boardName) {
      case 'Amazon Delivery System':
        return { backgroundColor: "#66990033", boxShadow: "0px 0px 90px 0px #669900CC inset" };
      case 'SuDoKu':
        return { backgroundColor: "#D4881733", boxShadow: "0px 0px 90px 0px #D48817CC inset" };
      case 'Maze':
        return { backgroundColor: "CC333333", boxShadow: "0px 0px 90px 0px #CC3333CC inset" };
      case 'Soccer':
        return { backgroundColor: "#66990033", boxShadow: "0px 0px 90px 0px #669900CC inset" };
      case 'Moon Mission':
        return { backgroundColor: "#D4881733", boxShadow: "0px 0px 90px 0px #D48817CC inset" };
      case 'Restaurant':
        return { backgroundColor: "CC333333", boxShadow: "0px 0px 90px 0px #CC3333CC inset" };
      default:
        return { backgroundColor: "#66990033", boxShadow: "0px 0px 90px 0px #669900CC inset" };
    }
  }


  return (


    <div>
      {isLoading ? (
        // Loader Spinner
        <div className="loader-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        // Main Content
        <div>
          <img
            className="" width={'100%'}
            src={assets.course_v4.lets_play_learn_banner}

          ></img>
          <Container className="self-learning-main-container pt-2">
            <p className="challenges-text-title-cgv4 mt-2">CHALLENGES</p>
            {/* <Button className="join-with-code-btn mb-3" >Add Kid</Button> */}
            <Box sx={{ width: '100%' }}>
              {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Games" {...a11yProps(0)} />
            <Tab label="Whiteboard" {...a11yProps(1)} />
          </Tabs>
        </Box> */}
              {/* GAMES */}
              {myBoards.length > 0 ? <div className="row">
                {
                  myBoards.sort((a, b) => {
                    // Sort 'coming_soon' cards last
                    if (a.coming_soon && !b.coming_soon) return 1;
                    if (!a.coming_soon && b.coming_soon) return -1;
                    // Sort by 'sequence' if 'coming_soon' status is the same
                    return a.sequence - b.sequence;
                  }).map((obj, index) => {
                    const boardStyles = getBoardStyles(obj?.name);
                    return (
                      <div key={`self-learning-whiteboards-` + obj.id} className={`col-12 col-lg-3 col-md-4 col-sm-12 pb-1 pb-5`}>
                        {obj.coming_soon ? (
                          // Coming Soon Card
                          <Card className={`cursor-pointer missions_game_card_cv4 ${obj.isFree && subscriptionStatus == "trialing" ? '' : subscriptionStatus == "active" ? '' : 'dim-opacity'}`} onClick={(e) => openDetail(obj?.name, obj)}
                            style={{ backgroundColor: "rgb(90 104 96 / 36%);", boxShadow: "#111112cf 0px 0px 90px 0px inset" }} >
                            <div className="missions_game_card_img_cv4">

                              <img
                                className="game_card_img_cv4" width={'100%'}
                                src={obj?.image || assets.whiteboard}
                                alt={obj?.name}
                              ></img>
                            </div>

                            <div className="missions_game_card_content">
                              <p className="missions_game_card_title">{obj?.name}</p>

                              {subscriptionStatus == "trialing" ?
                                <div className="d-flex align-items-center justify-content-center">
                                  <button className="comimg_soon_trialing_st_btn">
                                    <p className="mb-0 me-2 btn-text-hv4">Coming soon</p>
                                    <img
                                      className=""
                                      width={40}
                                      src={assets.homev4.white_with_blue_arrow}
                                    ></img>
                                  </button>
                                </div> : ''}
                            </div>
                            {subscriptionStatus == "active" ? <div className="d-flex align-items-center justify-content-center comimg_soon_active_st_btn">
                              <p className="mb-0 btn-text-hv4">Coming soon</p>
                            </div> : ''}
                          </Card>
                        ) : (
                          // Regular Game Card
                          <Card className={`cursor-pointer missions_game_card_cv4 ${obj.isFree && subscriptionStatus == "trialing" ? '' : subscriptionStatus == "active" ? '' : 'dim-opacity'}`} style={boardStyles} onClick={(e) => openDetail(obj?.name, obj)}>
                            <div className="missions_game_card_img_cv4">

                              <img
                                className="game_card_img_cv4" width={'100%'}
                                src={obj?.image || assets.whiteboard}
                                alt={obj?.name}
                              ></img>
                            </div>

                            <div className="missions_game_card_content">
                              <p className="missions_game_card_title">{obj?.name}</p>

                              {
                                obj.isFree && subscriptionStatus == "trialing" ?
                                  <div className="d-flex align-items-center justify-content-center">
                                    <button className="start-for-free-cv4">
                                      <p className="mb-0 me-2 btn-text-hv4">Start for free</p>  <img
                                        className="" width={40}
                                        src={assets.homev4.white_with_blue_arrow}
                                      ></img>
                                    </button>
                                  </div> : subscriptionStatus == "active" ? ''
                                    :
                                    <div className="d-flex align-items-center justify-content-center">
                                      <button className="start-for-free-cv4">
                                        <p className="mb-0 me-2 btn-text-hv4">Purchase</p>  <IconButton aria-label="locked">
                                          <LockTwoToneIcon />
                                        </IconButton>
                                      </button>
                                    </div>
                              }

                            </div>
                          </Card>
                        )}
                      </div>
                    )
                  })
                }
              </div> :
                <div className="row">
                  <p>No records found. Click the link to buy <a className="buy-plan-link" onClick={(e) => navigateTo("/subscription")}>subscription</a>.</p>
                </div>
              }
              {subscriptionStatus == "canceled" ? <div className="row">
                <p>Click the link to buy <a className="buy-plan-link" onClick={(e) => navigateTo("/subscription")}>subscription</a>.</p>
              </div> : ""}
            </Box>

          </Container>
        </div>
      )}
    </div>
  )
}
